<div class="flex justify-content-center align-content-center align-items-center text-white font-bold"
    *ngIf="userDetails">
    <div class="lg:flex md:hidden gap-2 align-content-center justify-content-center align-items-center hidden" *ngIf="userDetails">
        {{userDetails.LoginEmail}}
    </div>
    <p-divider [align]="'left'" type="solid" layout="vertical" styleClass="py-1 border-1 lg:flex md:hidden"></p-divider>
    <div>
        <app-session-timer (clicked)="onSessionresume()"></app-session-timer>
    </div>
    <p-divider [align]="'left'" type="solid" layout="vertical" styleClass="py-1 border-1"></p-divider>
    <div class="flex user-profile-container">
        <div>
            <p-button (onClick)="op.toggle($event)" icon="pi pi-user-circle" styleClass="text-white p-button-lg"
                [rounded]="true" [text]="true">
            </p-button>
        </div>
        <p-overlayPanel #op styleClass="user-profile-container " (onShow)="onUserMenuShown()"
            (onHide)="onUserMenuHide()">
            <ng-template pTemplate="content">
                <div class="flex flex-column user-overlay-container">
                    <div>
                        <ul class="list-none p-0 m-0 pt-3">
                            <li class="flex align-items-center gap-2">
                                <span class="font-bold">IP Address : </span> <span>{{ userDetails.DeviceIP }}</span>
                            </li>
                            <li class="flex align-items-center gap-2">
                                <span class="font-bold">Name : </span>
                                <span>{{userDetails.FirstName}}</span>
                                <span *ngIf="userDetails.LastName">&nbsp;{{userDetails.LastName}}</span>
                            </li>
                            <li class="flex align-items-center gap-2">
                                <span class="font-bold">Email : </span>
                                <span>{{ userDetails.LoginEmail }}</span>
                            </li>
                            <li class="flex align-items-center gap-2" *ngIf="deviceInfo">
                                <span class="font-bold">Location: </span>
                                <span>Lat: {{ deviceInfo.latitude }}, Long: {{deviceInfo.longitude}}</span>
                            </li>
                            <li class="flex align-items-center gap-2" *ngIf="deviceInfo">
                                <span class="font-bold">Device Info: </span>
                                <span>{{ deviceInfo.deviceType }}, {{deviceInfo.platform}},
                                    {{deviceInfo.browserName}}</span>
                            </li>
                            <li>
                                <span class="font-bold">App Version: </span>
                                <span>{{ appVersion }}</span>
                            </li>
                            <li class="border-top-1 p-0">
                                <p-button icon="pi pi-bell" label="Notifications"
                                    styleClass="min-w-full p-button-lg profile-button" [text]="true"></p-button>
                            </li>
                            <li class="border-top-1 p-0">
                                <p-button icon="pi pi-power-off" label="Logout"
                                    styleClass="min-w-full p-button-danger p-button-lg  profile-button" [text]="true"
                                    (onClick)="onLogoutClicked()"></p-button>
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-template>
        </p-overlayPanel>

    </div>
</div>