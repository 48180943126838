@if(!this.template){
<div class="flex align-items-center w-7rem" (click)="clicked.emit()">
    @if(!this.iconTemplate){
    <i class="pi pi-hour-glass mr-2"></i>
    }
    @else{
    <ng-template [ngTemplateOutlet]="iconTemplate"></ng-template>
    }

    @if(!this.iconTemplate){
    <span class="text-lg font-semibold">
        @if(timeRemaining.days){
            <span>
                {{ timeRemaining.minutes }}d
            </span>
        }

        @if(timeRemaining.hours){
            <span>
                {{ timeRemaining.minutes }}h
            </span>
        }

        @if(timeRemaining.minutes){
        <span>
            {{ timeRemaining.minutes }}m
        </span>
        }

        @if(timeRemaining.seconds){
        <span>
            {{timeRemaining.seconds }}s
        </span>
        }
    </span>
    }
    @else{
    <ng-template [ngTemplateOutlet]="timerTemplate"></ng-template>
    }

</div>
}
@else{
<ng-template [ngTemplateOutlet]="template"></ng-template>
}