<div *ngIf="title" class="text-white font-bold pt-2 hidden md:block">
    <span class="text-xl">{{title.title}}</span>
    <span *ngIf="title.subTitle" class="text-xl">
        - {{title.subTitle}}
    </span>
</div>

<div *ngIf="title" class="text-white font-bold pt-2 md:hidden">
    <span *ngIf="title.subTitle" class="text-xl">
        {{title.subTitle}}
    </span>
</div>