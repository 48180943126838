import { AfterViewInit, Component, OnInit, ViewChild, inject, Renderer2 } from '@angular/core'
import { UserDetails } from '../../../models/Login'
import { BaseComponent } from '../../../components/base.component';
import { AppEventService, EVENT_TYPE } from '../../../services/app-event.service';
import { UserStateService } from '../../../store/state/user/user.state.service';
import { AppStateService } from '../../../store/state/app/app.state.service';
import { Device } from '../../../models/Device';
import { Token } from '../../../models/Gateway';
import { AuthService } from '../../../services/auth.service';
import { take } from 'rxjs';
import { SessionTimerComponent } from '../../../components/session-timer/session-timer.component';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from '../../../services/environment.service';
@Component({
  selector: 'app-profile',
  templateUrl: './app-profile.component.html',
  styleUrl: './app-profile.component.scss'
})
export class AppProfileComponent extends BaseComponent implements OnInit, AfterViewInit {
  eventService: AppEventService = inject(AppEventService)
  #userStateService = inject(UserStateService)
  #appStateService = inject(AppStateService)
  #authService: AuthService = inject(AuthService)
  #environmentService: EnvironmentService = inject(EnvironmentService)
  #renderer2: Renderer2 = inject(Renderer2)
  #document: Document = inject(DOCUMENT);
  @ViewChild(SessionTimerComponent) sessionTimer: SessionTimerComponent;

  userDetails: UserDetails;
  deviceInfo: Device;
  tokenInfo: Token;
  constructor() {
    super();
  }
  ngAfterViewInit(): void {
    this.subscriptions.add(this.#appStateService.tokenSelector$.subscribe({
      next: () => {
        this.tokenInfo = this.#appStateService.getToken();
        if (this.tokenInfo) {
          this.sessionTimer.start(this.tokenInfo.startDate, this.tokenInfo.endDate);
        }
      }
    }));
  }
  ngOnInit(): void {
    this.userDetails = this.#userStateService.getUser();
    this.deviceInfo = this.#appStateService.getDevice();

  }

  onLogoutClicked() {
    this.eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }

  onSessionresume() {
    this.#authService.fetchToken().pipe(take(1)).subscribe();
  }

  onUserMenuShown() {
    this.#document.body.classList.add('modal-open');
  }
  onUserMenuHide() {
    this.#document.body.classList.remove('modal-open');
  }
  get appVersion() {
    return this.#environmentService.env.appVersion;
  }
}
