import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AppStickyHeaderComponent } from './app-sticky-header/app-sticky-header.component'
import { AppSidebarComponent } from './app-sidebar/app-sidebar.component'
import { AppFooterComponent } from './app-footer/app-footer.component'
import { AppLayoutComponent } from './app-layout.component'
import { AppProfileComponent } from './app-profile/app-profile.component'
import { AppLayoutService } from './services/app-layout.service'
import { RouterModule } from '@angular/router'
import { AppBreadcrumbComponent } from './app-breadcrumb/app-breadcrumb.component'
import { UnauthorizedHeaderComponent, UnauthorizedLayoutComponent } from './unauthorized-layout/unauthorized-layout.component'
import { AppBaseModule } from '../app-base.module'
import { MenuItemComponent } from './app-sidebar/menu/menu-item/menu-item.component'
import { MobileHeaderComponent } from './mobile-header/mobile-header.component'
import { PrivacyPolicyComponent } from '../../../features/privacy-policy/privacy-policy.component';
import { AuthorizedLayoutComponent } from './authorized-layout/authorized-layout.component';
import { AuthorizedHeaderComponent } from './authorized-layout/authorized-header/authorized-header.component';
import { PageTitleComponent } from './page-title/page-title.component'
import { SessionTimeoutModule } from '../../components/sesion-timeout/sesion-timeout.component';
import { SessionTimerModule } from '../../components/session-timer/session-timer.component'


@NgModule({
  declarations: [
    AppStickyHeaderComponent,
    AppSidebarComponent,
    AppFooterComponent,
    AppLayoutComponent,
    AppProfileComponent,
    AppBreadcrumbComponent,
    UnauthorizedLayoutComponent,
    MenuItemComponent,
    MobileHeaderComponent,
    UnauthorizedHeaderComponent,
    PrivacyPolicyComponent,
    AuthorizedLayoutComponent,
    AuthorizedHeaderComponent,
    PageTitleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppBaseModule,
    SessionTimeoutModule,
    SessionTimerModule
  ],
  exports: [AppLayoutComponent, UnauthorizedHeaderComponent, AuthorizedHeaderComponent],
  providers: [AppLayoutService]
})
export class AppLayoutModule { }
