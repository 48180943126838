export enum Message {
  Success = "Success",
  Error = "Error",
  Warning = "Warning",
  Info = "Info",
  ErrorMessage = "An error occurred. Please try again later.",
  ErrorMessageLocationAccess = "Location access blocked. Please enable to continue",
  ErrorMessageCountryAccess = "Access blocked. Application is not available in your country.",
  ErrorMessageDeviceId = "Error in getting deviceId. Please try again later.",
  ErrorMessageDeviceType = "Error in getting device type. Please try again later.",
  ErrorMessageDeviceIpAddress = "Error in getting device IP address. Please try again later.",
  ErrorMessageDeviceGeoCoordinates = "Error in getting device geo coordinates. Please try again later.",
  ErrorMessageDeviceHashId = "Error in getting device hash id. Please try again later.",
  ErrorMessageDevicermation = "Error in getting device information. Please try again later.",
  ErrorMessageGetToken = "Error in getting token. Please try again later.",
  ErrorMessageSession = "Error in starting the session. Please try again later.",
  ErrorMessageTokenDate = "Error in getting token date. Please try again later.",
  ErrorMessageGetKeys = "Error in getting keys. Please try again later.",
  LogoutMessage = "You have been logged out.",
  InvalidCredentials = "Invalid username or password.",
  EncryptedTextCopiedToClipboard = "Encrypted text copied to clipboard.",
  DecryptedTextCopiedToClipboard = "Decrypted text copied to clipboard.",
  EncryptedTextPastedFromClipboard = "Encrypted text pasted from clipboard.",
  DecryptedTextPastedFromClipboard = "Decrypted text pasted from clipboard.",
  EncryptedDataCleared = "Encrypted data cleared.",
  DecryptedDataCleared = "Decrypted data cleared.",
  Encryption = "encryption",
  ErrorMessageLoginFailed = "Incorrect email/password",
  ErrorMessageIncorrectLoginCredentials = "Incorrect email or password. Please verify and try again.",
  PinResentSuccess = "Pin resent successfully.",
  Forbidden = "Your Session has been expired",
  NotAuthporized = "Not Authorized",
}

export const serviceDownMessages = [
  {
    severity: 'warn',
    detail: 'Provider Services Down. Only Lead Data will be collected',
  }
]